import Vue from 'vue';
import Vuex from 'vuex';
import YS from '../YS';

Vue.use(Vuex);
// Vue.set(Vuex.Store.user);
export default new Vuex.Store({
  state: {
    //跟据用户时区不同，计算用户当前的国家时间
    onlineTime: {
      //16代表世界时间，对应的是中国早上8点
      //4代表世界时间，对应的是中国下午8点
      startTime: 0,
      endTime: 12,
    },
    mtopfloating: {
      top: false,
    },
    // defaultimg: 'this.src="' + require('../assets/img/leave_vip.png') + '"',
    defaultimg: 'this.src="' + require('../assets/img/fil_b.png') + '"',
    defaultimg_bo: 'this.src="' + require('../assets/bo/fil_b.png') + '"',
    defaultimg_u: 'this.src="' + require('../assets/u/fil_u.jpg') + '"',
    defaulava: 'this.src="' + require('../assets/img/avatar1-32.png') + '"',
    defaulava_bo: 'this.src="' + require('../assets/bo/avatar1-32.png') + '"',
    defaulava_u: 'this.src="' + require('../assets/u/avatar1-32.png') + '"',
    routelist: [], //记录路由地址
    loginBefore: [], //登录前置处理内容
    offLight: false, //是否关灯
    payList: [], //支付接口
    payType: [], //支付接口
    message: {
      messagenow: 0,
      message_total: null,
      message_list: [],
    },
    playbody: {
      playList: [],
      video_history_total: null,
    },

    video_rank_list: {}, //首页各类推荐数据
    video_latest_list: {}, //首页各类排行榜数据
    floating_list: [], //悬浮窗数据
    search_total: 0,
    searchNodes: {
      keyword: '',
      category_id: '',
      year: '',
      region: '',
      state: '',
      page: 1,
      pagesize: 15,
    },
    userimg: require('@/assets/img/avatar1-32.png'),
    enter: false,
    verCode: false,
    userStatus: 'userLogin', //弹窗类型
    menutotal: [], //导航栏菜单
    menudist: {}, //菜单字典
    menuVice: [], //导航栏二级菜单
    leftListcss: {},
    code: {
      count: '验证码', //验证码
      timer: null, //验证码
      show: true,
    },
    user: {},
    islogin: false,
    isvip: true,
    isfirst: false,
    mhome: true,
    mhomeshow: {
      lant: false,
      title: true,
      swipe: true,
      guanggao: true,
      guanggao2: true,
      movie: true,
      fil: true,
    },
    showWindows: {
      navMenu: false,
      navVice: false,
      navSearch: false,
      navNT: false,
      navWF: false,
      navWatch: false,
      notice: false,
      crearWin: false,
      ultraWin: false,
      user: false,
    },
    showlogin: {
      loginReg: false, // 登录   右上角登录/注册
      vipReg: false, //开通vip弹窗
      userReg: false, //修改用户名弹窗
      custReg: false, //联系客服
      secReg: false, //安全设置-修改密码
      mailReg: false, //账户中心-绑定修改邮箱
      secmailReg: false, //安全设置-邮箱修改密码
      messReg: false, //消息通知窗口
      protocol: false, //用户协议窗口
      terms: false, //VIP服务条款
      confirn: false, //确认关闭购买VIP弹窗
      payresult: false, //确认支付结果弹窗
      vipcardWIN: false, //卡密充值会员弹窗
      playerror: false, //播放页报错反馈
      mustvip: false, //播放页，需要vip权限才能观看
      mustlogin: false, //播放页，需要登录才能高清
      upavatar: false, //上传头像弹窗
      small: false, //是否清空所有信息
      success: false, //公用成功弹窗
      failure: false, //公用失败弹窗
      outlogin: false, //确定退出登录
      kefugo: false, //联系客服
      payres: false, //获取支付结果
      hiddenWIN: false, //确认隐藏密码
      mailBind: false, //绑定邮箱
      phoneBind: false, //绑定手机
      ubPop: false, //UB新弹窗
      ubPop_succ: false, //UB新弹窗
      res_mail: false, //重置密码
      res_phone: false, //重置密码
      mustBan: false, //需要绑定的窗口
      wuyeChange: false, //午夜版修改密码
    },
    //左侧列表信息以及图标
    leftComponent: [
      {
        name: '播放记录',
        imgnow: require('../assets/img/his-l-1.png'),
        imgact: require('../assets/img/his-l-1-act.png'),
        img: require('../assets/img/his-l-1.png'),
        route: '/history',
      },
      {
        name: '消息&通知',
        imgnow: require('../assets/img/his-l-2.png'),
        imgact: require('../assets/img/his-l-2-act.png'),
        img: require('../assets/img/his-l-2.png'),
        route: '/message',
      },
      // {
      //   name: '充值记录',
      //   imgnow: require('../assets/img/his-l-3.png'),
      //   imgact: require('../assets/img/his-l-3-act.png'),
      //   img: require('../assets/img/his-l-3.png'),
      //   route: '/recharge',
      // },
      {
        name: '账户信息',
        imgnow: require('../assets/img/his-l-4.png'),
        imgact: require('../assets/img/his-l-4-act.png'),
        img: require('../assets/img/his-l-4.png'),
        route: '/account',
      },
      // {
      //   name: '关系绑定',
      //   imgnow: require('../assets/img/his-l-9.png'),
      //   imgact: require('../assets/img/his-l-9-act.png'),
      //   img: require('../assets/img/his-l-9.png'),
      //   route: '/relationship',
      // },
      {
        name: '帮助中心',
        imgnow: require('../assets/img/his-l-5.png'),
        imgact: require('../assets/img/his-l-5-act.png'),
        img: require('../assets/img/his-l-5.png'),
        route: '/help',
      },
      {
        name: '意见反馈',
        imgnow: require('../assets/img/his-l-8.png'),
        imgact: require('../assets/img/his-l-8-act.png'),
        img: require('../assets/img/his-l-8.png'),
        route: '/opinion',
      },
      {
        name: '安全设置',
        imgnow: require('../assets/img/his-l-6.png'),
        imgact: require('../assets/img/his-l-6-act.png'),
        img: require('../assets/img/his-l-6.png'),
        route: '/security',
      },
      {
        name: '退出登录',
        imgnow: require('../assets/img/his-l-7.png'),
        imgact: require('../assets/img/his-l-7-act.png'),
        img: require('../assets/img/his-l-7.png'),
      },
    ],
    //白色/黑色 主题模式
    model: {
      white: {
        mapimg: {
          up: require('../assets/img/up_white.png'),
          down: require('../assets/img/down_white.png'),
        },
      },
      black: {
        mapimg: {
          up: require('../assets/img/up_black.png'),
          down: require('../assets/img/down_black.png'),
        },
      },
    },
    movieList: [
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
      { name: '乡村爱情13', num: '8.6', label: ['2021', '科幻', '爱情', '动作'] },
    ],
  },
  getters: {
    doneTodos: (state) => {
      return state.todos.filter((todo) => todo.done);
    },
  },
  mutations: {
    changeleftListcss(state, data) {
      state.leftListcss = data;
    },
    //添加路由地址
    pushrout(state, path) {
      state.routelist.push(path);
    },
    //删除路由地址
    delrout(state) {
      if (state.routelist[0]) {
        var a = state.routelist.pop();
        window.location.href = a;
      } else {
        window.location.href = '/';
      }
    },
    //改变登录前置方法内容
    addloginBefore(state, way) {
      state.loginBefore.push(way);
    },
    //使用登录前置方法内容
    useloginBefore(state) {
      state.loginBefore.forEach((e) => {
        e();
      });
      state.loginBefore = [];
    },
    //开关灯变成相反
    loghtBool(state) {
      // debugger;
      state.offLight = !state.offLight;
      localStorage.setItem('offLight', state.offLight);
    },
    //获取开关灯状态
    getloghtBool(state) {
      // debugger;
      if (localStorage.getItem('offLight')) {
        state.offLight = JSON.parse(localStorage.getItem('offLight'));
      } else {
        state.offLight = false;
        localStorage.setItem('offLight', false);
      }
    },
    //自定义push
    changeEverypush(state, data) {
      if (data.key3 != undefined) {
        state[data.key][data.key2][data.key3].push(data.value);
      } else if (data.key2 != undefined) {
        state[data.key][data.key2].push(data.value);
      } else {
        state[data.key].push(data.value);
      }
    },
    //自定义改变
    changeEvery(state, data) {
      if (data.key2 != undefined) {
        state[data.key][data.key2] = data.value;
      } else {
        state[data.key] = data.value;
      }
    },
    // 修改菜单当前选择的下标
    changeMenuVice(state, index) {
      state.menuVice = state.menutotal[index].list;
    },
    //mhome改变状态
    mhome(state, a) {
      state.mhome = a;
    },
    //悬浮窗传数据
    floating(state, a) {
      state.floating_list = a;
    },
    // //改变移动端首页的显示与隐藏
    // changemhome(state) {

    // },
    //跟据本地存储检查登录状态
    changelogin(state) {
      var tokenval = JSON.parse(localStorage.getItem('token'));
      if (tokenval && Date.parse(new Date()).toString().substr(0, 10) < tokenval.expired_time) {
        //token有效
        state.islogin = true;
        // 记录用户信息到vuex
        var objuser = JSON.parse(localStorage.getItem('user'));
        state.user = objuser;
      } else {
        //token无效
        state.islogin = false;
        state.user = {};
      }
    },
    getisvipCode(state) {
      state.isvip = true;
      // debugger;
      // if (Date.parse(new Date()).toString().substr(0, 10) < state.user.vip_expired_time) {
      //   state.isvip = true;
      // } else {
      //   state.isvip = false;
      // }
    },
    changeStatus(state, type) {
      var loginIpu = document.getElementById('loginIpu');
      var codeInput = document.getElementById('codeIpu');
      switch (type) {
        case '用户名登录':
          state.userStatus = 'userLogin';
          YS.tagChange('left');
          break;
        case '返回登录':
          state.userStatus = 'userLogin';
          YS.tagChange('left');
          YS.codeHidden(loginIpu, codeInput);
          break;

        case '邮箱登录':
          state.userStatus = 'mailLogin';
          YS.tagChange('right');
          break;

        case '注册账号':
          state.userStatus = 'userRes';
          // state.verCode = true;
          YS.tagChange('left');
          YS.codeHidden(loginIpu, codeInput);
          break;
        case '立即注册':
          // state.userStatus = 'mailRes';

          break;
        case '用户名注册':
          state.userStatus = 'userRes';
          YS.tagChange('left');
          YS.codeHidden(loginIpu, codeInput);
          break;

        case '邮箱注册':
          state.userStatus = 'mailRes';
          YS.tagChange('right');
          YS.codeShow(loginIpu, codeInput);
          break;

        case '立即登录':
          state.userStatus = 'userLogin';
          break;

        case '忘记密码？':
          state.userStatus = 'forget';
          YS.codeShow(loginIpu, codeInput);
          break;

        case '账号注册':
          state.userStatus = 'userRes';
          YS.tagChange('left');
          YS.codeHidden(loginIpu, codeInput);
          break;
        case '手机注册':
          state.userStatus = 'phoneRes';
          YS.tagChange('left');
          YS.codeHidden(loginIpu, codeInput);
          break;

        case false:
          YS.codeHidden(loginIpu, codeInput);
          break;
        case true:
          debugger;
          YS.codeShow(loginIpu, codeInput);
          break;

        default:
          break;
      }
    },
    // change_video_latest_list(state, index, data) {
    //   state.video_latest_list['i' + index] = data;
    // },
    // change_video_rank_list(state, index, data) {
    //   state.video_rank_list['i' + index] = data;
    // },
  },

  actions: {},
  modules: {},
});
