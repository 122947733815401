window.g = {
  // BASE_URL: 'http://51.79.223.179:8112', //正式服
  BASE_URL: 'https://api.ub1818.com', //正式服
  // BASE_URL: 'http://api-h5.yunx.pro', //正式服

  // BASE_URL: 'https://api-h5.uvod.tv', //正式服
  // BASE_URL: 'http://192.168.31.8:8112', //测试服

  WEBSOCKET_URL: 'ws://ws.uvod.tv:9501', //正式服 - websocket地址
  // WEBSOCKET_URL: 'ws://192.168.1.8:9501', //websocket地址

  OLD_URL: 'http://51.79.223.179:8116', //旧版网址

  // WEB_URL: 'https://www.uvod.tv', // 正式服首页
  WEB_URL: 'https://www.ub1818.com', // 正式服首页
  // WEB_URL: 'http://localhost:8080', // 正式服首页

  // MOBILE_URL: 'https://m.uvod.tv', //正式服手机端
  MOBILE_URL: 'https://m.ub1818.com', //手机端
  // MOBILE_URL: 'https://m.ub2828.com', //手机端
  // MOBILE_URL: 'http://localhost:8080', //正式服手机端
  WEB_URL_BO: 'https://hkbo.ub2828.com', // 正式服首页
  MOBILE_URL_BO: 'https://m-hkbo.ub2828.com', //手机端

  WEB_URL_U: 'https://usam.ub2828.com', // 正式服首页
  MOBILE_URL_U: 'https://m-usam.ub2828.com', //手机端

  // axios_console: true,
  axios_console: false,

  // 1=ub1818,2=bo,3=u影视
  ub_index: 1,
};
