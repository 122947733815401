import str from '@/utils/str';
import CryptoJS from 'crypto-js';
import NodeRSA from 'node-rsa';
import md5 from 'md5';
import rsaKeys from './rsa-keys';

export default {
  encrypt(data) {
    // const aesKey = '0123456789abcdef0123456789abcdef'
    const aesKey = str.random();

    const options = {
      iv: CryptoJS.enc.Utf8.parse('abcdefghijklmnop'),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };
    const raw = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(aesKey), options).toString();

    const RasPublic = new NodeRSA(rsaKeys.serverPublic);
    RasPublic.setOptions({ encryptionScheme: 'pkcs1' });
    const aesKeyCrypted = RasPublic.encrypt(aesKey, 'base64');
    return `${raw}.${aesKeyCrypted}`;
  },
  decrypt(raw) {
    const raws = raw.split('.');
    if (Object.keys(raws).length !== 2) {
      return null;
    }

    const RasPrivate = new NodeRSA(rsaKeys.clientPrivate);
    RasPrivate.setOptions({ encryptionScheme: 'pkcs1' });
    const aesKey = RasPrivate.decrypt(raws[1]);
    if (aesKey === '') {
      return null;
    }
    const options = {
      iv: CryptoJS.enc.Utf8.parse('abcdefghijklmnop'),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };
    const data = CryptoJS.AES.decrypt(raws[0], CryptoJS.enc.Utf8.parse(aesKey), options).toString(
      CryptoJS.enc.Utf8,
    );

    return data;
  },
  createSignature(token, data, timestamp) {
    const signature = md5(`${token}-${data}-${timestamp}`);
    return signature;
  },
};
