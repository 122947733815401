/**
 * 系统 api 专用请求, 需要与其他如 oss 文件上传等区分开来
 */

import axios from 'axios';
import utils from '@/utils';
import encryption from './encryption';
import '../../../static/config.js';
// import requestError from './error';
// import qs from "qs";

const instance = axios.create({
  baseURL: window.g.BASE_URL,
  timeout: 38 * 1000,
});

instance.interceptors.request.use(
  (config) => {
    let params = {};
    if (!config.normalRequest) {
      params = utils.dict.ksort(utils.dict.filter(config.data));
    }
    // const params = utils.dict.ksort(utils.dict.filter(config.data));
    if (window.g.axios_console) {
      console.log('********【发送】*******【 ', config.url, '】');
      console.log(params);
    }
    // const token = utils.storage.token.get();
    const token = JSON.parse(localStorage.getItem('token'));
    const tokenValue =
      !token || token.expired_time < Date.parse(new Date()).toString().substr(0, 10)
        ? ''
        : token.token;
    const timestamp = Date.now();
    const signature = encryption.createSignature(
      tokenValue,
      utils.dict.createHttpQuery(params),
      timestamp,
    );

    const headers = {
      'Content-Type': 'application/json',
      'X-TOKEN': tokenValue,
      'X-TIMESTAMP': timestamp,
      'X-SIGNATURE': signature,
    };
    config.headers = headers;

    if (!config.normalRequest) {
      config.data = encryption.encrypt(JSON.stringify(config.data));
    }

    // config.data = encryption.encrypt(JSON.stringify(config.data));

    // console.log('【发送】 ' + config);
    return config;
  },
  // (error) => {
  //   // const message = error.message;
  //   // requestError.show(message);
  //   // requestError.log(error)
  //   return Promise.reject();
  // },
);

instance.interceptors.response.use(
  (response) => {
    // console.log(response.data);
    const json = JSON.parse(encryption.decrypt(response.data));

    if (window.g.axios_console) {
      console.log('********【接收】*******【 ', response.config.url, '】');
      console.log(json);
    }

    if (typeof json !== 'object') {
      // requestError.show('请求出现异常');
      return Promise.reject(json);
    }
    if (json.error === 0) {
      return Promise.resolve(json.data || null);
    } else if (json.error === 21) {
      if (localStorage.getItem('token')) {
        // alert(json.message);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        // window.location.href = '/';
        window.location.reload();
      }
      // that.$router.push({ path: '/' });
    } else if (json.error > 0) {
      // requestError.show(json.message);
      return Promise.reject(json);
    }
  },
  // (error) => {
  //   // const message = error.message;
  //   // requestError.show(message);
  //   // requestError.log(error)
  //   return Promise.reject();
  // },
);

export default instance;
